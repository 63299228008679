@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './button-ghost-content-config';

.ace-c-button-ghost-content {
    // stylelint-disable-next-line
    @include typography($typography-styles, map-get($button-ghost-content-config, button-ghost-content-text-typography));

    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
        width: 24px;
        height: 24px;
        fill: map-get($button-ghost-content-config, button-ghost-content-icon-color);
        color: map-get($button-ghost-content-config, button-ghost-content-text-color);

        &--left {
            margin-right: 12px;
        }

        &--right {
            margin-left: 12px;
        }
    }

    &--is-focused {
        color: map-get($button-ghost-content-config, button-ghost-content-text-color-focus);

        .ace-c-button-ghost-content__icon {
            fill: map-get($button-ghost-content-config, button-ghost-content-icon-color-focus);
        }
    }

    &--is-disabled {
        color: map-get($button-ghost-content-config, button-ghost-content-text-color-disabled);

        .ace-c-button-ghost-content__icon {
            fill: map-get($button-ghost-content-config, button-ghost-content-icon-color-disabled);
        }
    }

    &--is-highlighted:not(#{&}--is-disabled) {
        color: map-get($button-ghost-content-config, button-ghost-content-text-color-highlighted);

        .ace-c-button-ghost-content__icon {
            fill: map-get($button-ghost-content-config, button-ghost-content-icon-color-higlighted);
        }
    }

    &:hover:not(#{&}--is-disabled):not(#{&}--is-focused) {
        color: map-get($button-ghost-content-config, button-ghost-content-text-color-hover);

        .ace-c-button-ghost-content__icon {
            fill: map-get($button-ghost-content-config, button-ghost-content-icon-color-hover);
        }
    }

    &:active:not(#{&}--is-disabled):not(#{&}--is-focused) {
        color: map-get($button-ghost-content-config, button-ghost-content-text-color-active);

        .ace-c-button-ghost-content__icon {
            fill: map-get($button-ghost-content-config, button-ghost-content-icon-color-active);
        }
    }
}
