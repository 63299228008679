@use 'sass:meta';
@use '../state-styles';

@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';

@import '../config';
@import '../functions';
@import '../mixins';
@import './toggle-switch-config';
@import './Checkbox.module';

@include state-styles.configure('ace-c-toggle-switch', $toggle-switch-config);

.ace-c-toggle-switch__track {
    position: relative;
    margin-right: map-get($toggle-switch-config, toggle-switch-label-spacing);
    width: 56px;
    height: 24px;
    border-radius: map-get($toggle-switch-config, toggle-switch-track-border-radius);
    border: map-get($toggle-switch-config, toggle-switch-track-border);
    background-color: map-get($toggle-switch-config, toggle-switch-track-background);
    padding: 0 2px;

    @include state-styles.element() using($get-design-token) {
        border: meta.call($get-design-token, toggle-switch-track-border);
        background-color: meta.call($get-design-token, toggle-switch-track-background);
    }
}

.ace-c-toggle-switch__toggle {
    position: absolute;
    left: 0;
    height: 16px;
    width: 16px;
    margin: 2px;
    background-color: map-get($toggle-switch-config, toggle-switch-toggle-color);
    border-radius: 50%;
    transition: 0.4s;

    .ace-c-toggle-switch--is-selected & {
        left: unset;
        right: 0;
    }

    @include state-styles.element() using($get-design-token) {
        background-color: meta.call($get-design-token, toggle-switch-toggle-color);
    }

    @include state-styles.element(
        $for-states: (active),
    ) using($get-design-token) {
        box-shadow: meta.call($get-design-token, toggle-switch-toggle-shadow);
    }
}
