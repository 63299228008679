@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';
.ace-c-invoice-overview-service-panel__additional-info {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ace-c-invoice-overview-service-panel__service-icon {
    flex: none;
}
