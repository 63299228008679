@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './loader-spinner-config';

.ace-c-loader-spinner {
    width: map-get($loader-spinner-config, loader-spinner-width);
    height: map-get($loader-spinner-config, loader-spinner-height);
    display: inline-block;
    box-sizing: border-box;
    border: map-get($loader-spinner-config, loader-spinner-border);
    border-radius: 50%;
    animation: rotation 1s linear infinite;
    position: relative;

    &__gradient-line {
        width: map-get($loader-spinner-config, loader-spinner-gradient-line-width);
        height: map-get($loader-spinner-config, loader-spinner-gradient-line-height);
        position: absolute;
        left: map-get($loader-spinner-config, loader-spinner-gradient-line-position-left);
        top: map-get($loader-spinner-config, loader-spinner-gradient-line-position-top);
        border-radius: 50%;
        -webkit-mask: map-get($loader-spinner-config, loader-spinner-gradient-line-mask);
        mask: map-get($loader-spinner-config, loader-spinner-gradient-line-mask);
        background: map-get($loader-spinner-config, loader-spinner-gradient-line-background);
    }

    &--s {
        width: map-get($loader-spinner-config, loader-spinner-size-s);
        height: map-get($loader-spinner-config, loader-spinner-size-s);

        .ace-c-loader-spinner__gradient-line {
            width: map-get($loader-spinner-config, loader-spinner-gradient-line-size-s);
            height: map-get($loader-spinner-config, loader-spinner-gradient-line-size-s);
        }
    }

    &--xs {
        width: map-get($loader-spinner-config, loader-spinner-size-xs);
        height: map-get($loader-spinner-config, loader-spinner-size-xs);

        .ace-c-loader-spinner__gradient-line {
            width: map-get($loader-spinner-config, loader-spinner-gradient-line-size-xs);
            height: map-get($loader-spinner-config, loader-spinner-gradient-line-size-xs);
        }
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
