@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './tab-bar-config';

.ace-c-tab-bar {
    display: inline-flex;
    background: map-get($tab-bar-config, tab-bar-background);

    &--secondary-background {
        background: map-get($tab-bar-config, tab-bar-secondary-background);
        width: 100%;
        height: 100%;
    }
}
