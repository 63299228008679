@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';
@import '../config';
@import './icon-config';

.ace-c-icon {
    display: inline-block;
    width: map-get($icon-config, icon-size-m);
    height: map-get($icon-config, icon-size-m);
    user-select: none;

    &--has-on-click {
        cursor: pointer;
    }

    &--no-margin {
        margin: 0;
    }

    &--s {
        width: map-get($icon-config, icon-size-s);
        height: map-get($icon-config, icon-size-s);
    }

    &--m {
        width: map-get($icon-config, icon-size-m);
        height: map-get($icon-config, icon-size-m);
    }

    &--l {
        width: map-get($icon-config, icon-size-l);
        height: map-get($icon-config, icon-size-l);
    }

    &--xl {
        width: map-get($icon-config, icon-size-xl);
        height: map-get($icon-config, icon-size-xl);
    }

    &--xxl {
        width: map-get($icon-config, icon-size-xxl);
        height: map-get($icon-config, icon-size-xxl);
    }
}

$icon-color-states: (
    warning,
    warning-light,
    warning-dark,
    success,
    contrast,
    disabled,
    disabled-light,
    medium,
    highlight,
    highlight-light,
    highlight-pressed,
    highlight-hover
);

.ace-c-icon__symbol {
    width: 100%;
    height: 100%;
    display: inherit;
    fill: map-get($icon-config, icon-symbol-default);

    @each $icon-color in $icon-color-states {
        .ace-c-icon--color-#{$icon-color} & {
            fill: map-get($icon-config, icon-symbol-#{$icon-color});
        }
    }
}
