@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './date-field-config';

.ace-c-date-field {
    width: map-get($date-config, date-width);
    height: map-get($date-config, date-height);
    position: relative;
    padding: 0;
    transition: width 0.5s ease;
    background-color: inherit;
    display: map-get($date-config, date-display-behaviour);
    justify-content: flex-end;

    &--is-focused {
        border: map-get($date-config, date-border-focus);
        border-right: none;
        box-shadow: map-get($date-config, date-shadow);
    }
}

.ace-c-date-field__input {
    margin-bottom: 0;
    width: calc(280px - 56px);
    height: 100%;
    padding-right: map-get($date-config, date-input-padding);
    flex-grow: 1;

    &--is-focused {
        border: map-get($date-config, date-border-focus);
        box-shadow: map-get($date-config, date-shadow);
    }

    &--has-error {
        color: map-get($date-config, date-input-color-error);
        border: map-get($date-config, date-border-error);
        box-shadow: map-get($date-config, date-shadow-error);
    }

    .ace-c-date-field--is-disabled & {
        background: map-get($date-config, date-disabled-background-color);
        border: map-get($date-config, date-disabled-border);
        color: map-get($date-config, date-disabled-icon-background-color);
    }

    &--small {
        height: map-get($date-config, date-input-small-height);
    }

    &--medium {
        height: map-get($date-config, date-input-medium-height);
    }
}

.ace-c-date-field__icon {
    position: absolute;
    top: map-get($date-config, date-top);
    right: map-get($date-config, date-right);
    pointer-events: none;

    &--small & {
        top: 8px;
    }

    &--is-focused {
        svg {
            fill: map-get($date-config, date-icon-background-color-disabled);
        }
    }

    &:hover:not(#{&}--is-disabled):not(:focus) {
        background: map-get($date-config, date-icon-background-color-disabled);
    }

    &:focus {
        box-shadow: none;
    }

    svg {
        fill: map-get($date-config, date-icon-color);
    }
}
