@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';
.ace-c-communication-modal__file-list {
    height: 300px;
    overflow-y: auto;
}

.ace-c-communication-modal__file-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ace-c-communication-modal__aligned-paragraph {
    max-width: 35% !important;

    div {
        height: 24px;
        text-align: justify;
    }

    div::after {
        content: '';
        display: inline-block;
        width: 100%;
    }

    &--first-line {
        margin-top: 20px;
    }

    &--last-line {
        margin: 20px 0;
    }
}
