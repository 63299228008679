@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';
.lea-c-access-denied__panel {
    box-shadow: 1px 1px 8px 3px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    width: 602px;
    height: 414px;

    &-header {
        background: rgba(0, 130, 185, 0.1);
        padding: 50px 0;
        text-align: center;
        width: 100%;
        color: #005578;
    }

    &-logo {
        display: inline-block;
        width: 168px;
        height: 56px;
        background-image: url('./assets/images/lea-logo.svg');
        background-size: cover;
    }
}
