@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';
.ace-c-sc-document-upload-modal__file-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: table-cell;
    vertical-align: middle;

    & > i {
        transform: translate(0, 8px);
    }
}

.ace-c-sc-document-upload-modal__create_inbox_task {
    visibility: visible;

    &--hidden {
        visibility: hidden;
    }
}
