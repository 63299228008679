@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './pop-over-config';

.ace-c-pop-over {
    padding: map-get($pop-over-config, pop-over-spacing) 0;
    max-width: map-get($pop-over-config, pop-over-content-max-width);
}

.ace-c-pop-over__content {
    overflow: hidden;
    background: map-get($pop-over-config, pop-over-background);
    border-radius: map-get($pop-over-config, pop-over-border-radius);
    box-shadow: map-get($pop-over-config, pop-over-shadow);
    overflow-y: auto;
    max-height: 50vh;

    &--has-border {
        border: 1px solid map-get($pop-over-config, pop-over-border-color);
    }
}
