@import '/home/jenkins/agent/workspace/FE/DEV/lea-webapp/src/theme-config.scss';
.ace-c-arcgis-map-widget {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -48px;
    left: 0;
    visibility: hidden;

    &--is-visible {
        visibility: visible;
    }

    &--is-in-front {
        z-index: 1;
    }
}
